define("landing-page-ember/templates/components/skill-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "R2NMNGRm",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"col text-center\"],[8],[0,\"\\n  \"],[7,\"i\",true],[11,\"class\",[22,\"icon\"]],[8],[9],[0,\"\\n  \"],[7,\"p\",true],[8],[1,[22,\"name\"],false],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "landing-page-ember/templates/components/skill-icon.hbs"
    }
  });

  _exports.default = _default;
});