define("landing-page-ember/templates/components/cover-image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sIw2rft1",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"row image-row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"text-center centered purple special-font\"],[8],[0,\"Software Developer\"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\\n\\n\"],[14,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "landing-page-ember/templates/components/cover-image.hbs"
    }
  });

  _exports.default = _default;
});