define("landing-page-ember/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "D5MoHesK",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"container-fluid\"],[8],[0,\"\\n  \"],[5,\"navbar\",[],[[],[]]],[0,\"\\n  \"],[5,\"cover-image\",[],[[],[]]],[0,\"\\n  \"],[5,\"about-me\",[],[[],[]]],[0,\"\\n  \"],[5,\"skills\",[],[[],[]]],[0,\"\\n  \"],[5,\"projects\",[],[[],[]]],[0,\"\\n  \"],[5,\"contact\",[],[[],[]]],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "landing-page-ember/templates/application.hbs"
    }
  });

  _exports.default = _default;
});