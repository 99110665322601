define("landing-page-ember/templates/components/project-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9lF31J3w",
    "block": "{\"symbols\":[],\"statements\":[[7,\"a\",true],[11,\"href\",[22,\"pageLink\"]],[10,\"target\",\"_blank\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"card\"],[8],[0,\"\\n    \"],[7,\"img\",true],[11,\"src\",[22,\"imgSrc\"]],[10,\"class\",\"card-img-top\"],[11,\"alt\",[22,\"title\"]],[8],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"card-body\"],[8],[0,\"\\n      \"],[7,\"h4\",true],[10,\"class\",\"card-text\"],[8],[1,[22,\"title\"],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "landing-page-ember/templates/components/project-card.hbs"
    }
  });

  _exports.default = _default;
});