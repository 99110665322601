define("landing-page-ember/templates/components/about-me", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qlSkcfo0",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"row about-me align-items-center\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-12 text-center\"],[10,\"id\",\"about\"],[8],[0,\"\\n    \"],[7,\"h3\",true],[10,\"class\",\"mt-3\"],[8],[0,\"About Me\"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-sm-12 col-md-12 col-lg-6 picture text-center\"],[8],[0,\"\\n    \"],[7,\"img\",true],[10,\"src\",\"/assets/images/PortraitB&W.jpg\"],[10,\"alt\",\"person\"],[10,\"class\",\"me\"],[8],[9],[7,\"br\",true],[8],[9],[0,\"\\n    \"],[7,\"i\",true],[10,\"class\",\"fas fa-user p-3\"],[8],[9],[0,\"Katrina Fuller\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-sm-12 col-md-12 col-lg-6 info-about-me\"],[8],[0,\"\\n    \"],[7,\"p\",true],[8],[0,\"My name is Katrina Fuller. I am excited about starting my career as a Software Developer! I started teaching\\n      myself how\\n      to code after work and on the weekends through online sites like freeCodeCamp, Lynda, and Pluralsight. After 8\\n      months of\\n      studying, I found that I love coding and all of the challenges that come with it. I decided I wanted to pursue\\n      a career\\n      as a Software Developer. I looked at several options and found BoiseCodeWorks to be the right opportunity for\\n      me. After\\n      an intensive 13 weeks, and completion of BoiseCodeWorks immersive full-stack program, I am confident that I\\n      have refined\\n      the skills needed to start working as a Software Developer.\"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\\n\\n\"],[14,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "landing-page-ember/templates/components/about-me.hbs"
    }
  });

  _exports.default = _default;
});