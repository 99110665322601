define("landing-page-ember/templates/components/projects", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KqFJ+CYQ",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"row projects align-items-center\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-12 text-center\"],[10,\"id\",\"projects\"],[8],[0,\"\\n    \"],[7,\"h3\",true],[10,\"class\",\"mt-3\"],[8],[0,\"Projects\"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[1,[28,\"project-card\",null,[[\"pageLink\",\"imgSrc\",\"title\"],[\"https://dinner-made-easy.herokuapp.com/#/\",\"/assets/images/DinnerMadeEasy.PNG\",\"Dinner Made Easy\"]]],false],[0,\"\\n\\n  \"],[1,[28,\"project-card\",null,[[\"pageLink\",\"imgSrc\",\"title\"],[\"https://list-keeper.herokuapp.com/#/\",\"/assets/images/Kanban.PNG\",\"Kanban\"]]],false],[0,\"\\n\\n  \"],[1,[28,\"project-card\",null,[[\"pageLink\",\"imgSrc\",\"title\"],[\"https://katrinafuller.github.io/Inspire/\",\"/assets/images/Inspire.PNG\",\"Inspire\"]]],false],[0,\"\\n\\n  \"],[1,[28,\"project-card\",null,[[\"pageLink\",\"imgSrc\",\"title\"],[\"https://github.com/KatrinaFuller/ConsoleAdventure\",\"/assets/images/ConsoleAdventure.PNG\",\"Console Adventure\"]]],false],[0,\"\\n\\n  \"],[1,[28,\"project-card\",null,[[\"pageLink\",\"imgSrc\",\"title\"],[\"https://katrinafuller.github.io/TaskMaster/\",\"/assets/images/TaskMaster.PNG\",\"Task Master\"]]],false],[0,\"\\n\\n  \"],[1,[28,\"project-card\",null,[[\"pageLink\",\"imgSrc\",\"title\"],[\"https://katrinafuller.github.io/tip-calculator/\",\"/assets/images/TipCalculator.PNG\",\"Tip Calculator\"]]],false],[0,\"\\n\\n\"],[9],[0,\"\\n\\n\\n\"],[14,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "landing-page-ember/templates/components/projects.hbs"
    }
  });

  _exports.default = _default;
});