define("landing-page-ember/templates/components/navbar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YuL/kfTL",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"row navbar\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-3 col-md-2 col-lg-1\"],[8],[0,\"\\n    \"],[7,\"i\",true],[10,\"class\",\"fab fa-kaggle p-1 fa-4x purple\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-9 col-md-10 col-lg-4\"],[8],[0,\"\\n    \"],[7,\"h1\",true],[8],[0,\"Katrina Fuller\"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-lg-3\"],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-12 col-md-6 col-lg-1\"],[8],[0,\"\\n    \"],[7,\"a\",true],[10,\"href\",\"#about\"],[8],[0,\"\\n      \"],[7,\"p\",true],[10,\"class\",\"text-uppercase text-center\"],[8],[0,\"about\"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-12 col-md-6 col-lg-1\"],[8],[0,\"\\n    \"],[7,\"a\",true],[10,\"href\",\"#skills\"],[8],[0,\"\\n      \"],[7,\"p\",true],[10,\"class\",\"text-uppercase text-center\"],[8],[0,\"skills\"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-12 col-md-6 col-lg-1\"],[8],[0,\"\\n    \"],[7,\"a\",true],[10,\"href\",\"#projects\"],[8],[0,\"\\n      \"],[7,\"p\",true],[10,\"class\",\"text-uppercase text-center\"],[8],[0,\"projects\"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-12 col-md-6 col-lg-1\"],[8],[0,\"\\n    \"],[7,\"a\",true],[10,\"href\",\"#contact\"],[8],[0,\"\\n      \"],[7,\"p\",true],[10,\"class\",\"text-uppercase text-center\"],[8],[0,\"contact\"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\\n\"],[14,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "landing-page-ember/templates/components/navbar.hbs"
    }
  });

  _exports.default = _default;
});