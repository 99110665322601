define("landing-page-ember/templates/components/contact", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ioOmG1VJ",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"row contact-info align-items-center justify-content-center\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-12 text-center\"],[10,\"id\",\"contact\"],[8],[0,\"\\n    \"],[7,\"h3\",true],[10,\"class\",\"mt-3\"],[8],[0,\"Contact\"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-sm-6 col-lg-3 text-center\"],[8],[0,\"\\n    \"],[7,\"i\",true],[10,\"class\",\"fas fa-phone fa-3x p-2 \"],[8],[9],[7,\"br\",true],[8],[9],[0,\"\\n    \"],[7,\"h4\",true],[8],[0,\"(408) 892-8943\"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-sm-6 col-lg-3 text-center\"],[8],[0,\"\\n    \"],[7,\"i\",true],[10,\"class\",\"far fa-envelope fa-3x p-2 \"],[8],[9],[7,\"br\",true],[8],[9],[0,\"\\n    \"],[7,\"h4\",true],[8],[0,\"trinafuller@att.net\"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-12 text-center\"],[8],[0,\"\\n    \"],[7,\"a\",true],[10,\"href\",\"https://www.facebook.com/katrina.fuller.77\"],[10,\"target\",\"_blank\"],[8],[7,\"i\",true],[10,\"class\",\"fab fa-facebook-square fa-2x p-1 mb-3\"],[8],[9],[9],[0,\"\\n    \"],[7,\"a\",true],[10,\"href\",\"https://www.linkedin.com/in/katrina-c-fuller/\"],[10,\"target\",\"_blank\"],[8],[7,\"i\",true],[10,\"class\",\"fab fa-linkedin fa-2x p-1 mb-3\"],[8],[9],[9],[0,\"\\n    \"],[7,\"a\",true],[10,\"href\",\"https://github.com/KatrinaFuller\"],[10,\"target\",\"_blank\"],[8],[7,\"i\",true],[10,\"class\",\"fab fa-github fa-2x p-1 mb-3\"],[8],[9],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\\n\"],[14,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "landing-page-ember/templates/components/contact.hbs"
    }
  });

  _exports.default = _default;
});