define("landing-page-ember/templates/components/skills", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eEYFLnNy",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"row languages align-items-center\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-12 text-center\"],[10,\"id\",\"skills\"],[8],[0,\"\\n    \"],[7,\"h3\",true],[10,\"class\",\"mt-3\"],[8],[0,\"Skills\"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[1,[28,\"skill-icon\",null,[[\"icon\",\"name\"],[\"devicon devicon-html5-plain\",\"HTML\"]]],false],[0,\"\\n\\n  \"],[1,[28,\"skill-icon\",null,[[\"icon\",\"name\"],[\"devicon devicon-css3-plain\",\"CSS\"]]],false],[0,\"\\n\\n  \"],[1,[28,\"skill-icon\",null,[[\"icon\",\"name\"],[\"devicon devicon-bootstrap-plain\",\"Bootstrap\"]]],false],[0,\"\\n\\n  \"],[1,[28,\"skill-icon\",null,[[\"icon\",\"name\"],[\"devicon devicon-javascript-plain\",\"JavaScript\"]]],false],[0,\"\\n\\n  \"],[1,[28,\"skill-icon\",null,[[\"icon\",\"name\"],[\"devicon devicon-vuejs-plain\",\"Vue\"]]],false],[0,\"\\n\\n  \"],[1,[28,\"skill-icon\",null,[[\"icon\",\"name\"],[\"devicon devicon-csharp-plain\",\"C#\"]]],false],[0,\"\\n\\n\"],[9],[0,\"\\n\\n\\n\"],[14,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "landing-page-ember/templates/components/skills.hbs"
    }
  });

  _exports.default = _default;
});